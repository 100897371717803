@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'fonts.css';

.font-cooper-hewitt {
  font-family: 'CooperHewitt';
}

.group:hover .group-hover\:translate-0\.5 {
  --tw-translate-x: 0.125rem;
  --tw-translate-y: 0.125rem;
}

.pt-1\.25 {
  padding-top: 5px;
}
